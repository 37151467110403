import axios from "axios";

const scheduleCallURL =
   "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2ZtOvz33ALy1KMOkIb3yrb4pwUREpURzrFrkG2TwbRJHLMFAvX_Kxr7hLMg8yFbSu3q9jNeiei";

const sendEmail = async (emailData) => {
   // const apiUrl = "http://localhost:8888/.netlify/functions";
   const apiUrl = "https://nearshore-backend.netlify.app/.netlify/functions";

   try {
      const response = await axios.post(`${apiUrl}/sendEmail`, emailData, {
         headers: {
            "Content-Type": "application/json",
         },
      });

      return response.data;
   } catch (error) {
      console.error("Error sending email:", error);
      throw error.response ? error.response.data : error;
   }
};

export const sendLegislationEmail = async (recipientEmail) => {
   const emailData = {
      recipientEmail,
      subject: "Thank You for Downloading Our Hiring Guide!",
      text:
         `Hi,\n\n` +
         `Thanks for downloading our "Legislation for Hiring in Portugal" PDF. We hope it provides you with the essential information you need to navigate the Portuguese hiring landscape.\n\n` +
         `If you have any further questions or need additional support, please don't hesitate to reach out. Our team is here to assist you with your hiring needs, from recruitment to outsourcing.\n\n` +
         `Ready to take the next step? Schedule a call with us to discuss your specific requirements.\n\n` +
         `[Schedule a call]: ${scheduleCallURL}` +
         "\n\n" +
         `Best regards,\n` +
         `The Nearshore.pt Team\n` +
         `www.nearshore.pt`,
   };

   return sendEmail(emailData);
};

export const sendContactEmail = async (recipientEmail, recipientName) => {
   const emailData = {
      recipientEmail,
      subject: "Thanks for Reaching Out! We're on It!",
      text:
         "Hi " +
         recipientName +
         ",\n\n" +
         "Thanks for reaching out! We appreciate you contacting us and we've received your question. Our team will get back to you with a personalized response within 24 hours.\n\n" +
         "In the meantime, have you explored our HireWise staffing calculator? It provides transparent cost insights for hiring the IT professionals you need.\n\n" +
         "Check it out here: www.nearshore.pt/hirewise\n\n" +
         "You can also find it on our website at Nearshore.pt: nearshore.pt\n\n" +
         "Best regards,\n\n" +
         "The Nearshore.pt Team\n\n" +
         "www.nearshore.pt",
   };

   return sendEmail(emailData);
};

export const sendHireWiseEmail = async (recipientEmail, recipientName) => {
   const emailData = {
      recipientEmail,
      subject: "Your Personalized HireWise Staffing Quote is Ready!",
      text:
         "Hi " +
         recipientName +
         ",\n\n" +
         "Thank you for using HireWise! We've generated a real-time quote tailored to your specific staffing needs. This quote provides a clear breakdown of the costs associated with hiring the professionals you require.\n\n" +
         "Ready to take the next step?** Simply reply to this email and our team will be in touch shortly with a contract and an invoice.\n\n" +
         "Need more information?** Schedule a call with one of our experts to discuss your project in detail.\n\n" +
         `[Schedule a call]: ${scheduleCallURL}` +
         "\n\n" +
         "We look forward to working with you!\n\n" +
         "Best regards,\n\n" +
         "The Nearshore.pt Team\n\n" +
         "www.nearshore.pt",
   };

   return sendEmail(emailData);
};
