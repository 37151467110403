import axios from "axios";

const PORTAL_ID = "26789313";

export const FormTypes = {
   CONTACT: "CONTACT",
   HIREWISE: "HIREWISE",
   LEGISLATION: "LEGISLATION",
};

const FormTypeIds = {
   [FormTypes.CONTACT]: "5c419521-b4f0-475f-b334-92622ed2d3f8",
   [FormTypes.HIREWISE]: "64db01be-f37d-490a-bed0-5897417a69c5",
   [FormTypes.LEGISLATION]: "dbc120e2-98fe-4505-a8f9-7a807d025821",
};

/**
 * Submits form data to a HubSpot form using the HubSpot API.
 *
 * @param {Object} formData - An object containing the form data to be submitted.
 * @param {string} formData.email - The email address of the user.
 * @param {string} formData.firstname - The first name of the user.
 * @param {string} formData.company - The company name of the user.
 * @param {string} formData.message - The message from the user.
 * @param {string} formType - The type of form (e.g., CONTACT, HIREWISE, LEGISLATION).
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the submission was successful, or `false` if it failed.
 */

export const submitHubSpotForm = async (formData, formType) => {
   const formId = FormTypeIds[formType];
   if (!formId) {
      console.error(`Invalid form type: ${formType}`);
      return false;
   }

   const hubspotFormUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${formId}`;

   // Prepare the fields
   const formattedData = {
      fields: Object.entries(formData)
         .filter(([_, value]) => value !== undefined) // Exclude undefined values
         .map(([name, value]) => ({
            name,
            value,
         })),
   };

   try {
      // Axios POST request with JSON data
      const response = await axios.post(hubspotFormUrl, formattedData, {
         headers: {
            "Content-Type": "application/json", // HubSpot expects application/json
         },
      });

      if (response.status === 200) {
         console.log("Form submitted successfully:", response.data);
         return true;
      } else {
         console.error("Submission failed with status:", response.status);
         return false;
      }
   } catch (error) {
      console.error(
         "Error during form submission:",
         error.response?.data || error.message
      );
      return false;
   }
};

/**
 * Validates an email address using a regular expression pattern.
 *
 * @param {string} email - The email address to validate.
 * @returns {boolean} - Returns `true` if the email address is valid, otherwise `false`.
 */
export const validateEmail = (email) => {
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   return emailPattern.test(email);
};
