import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
   display: flex;
   align-items: center;
   flex-grow: 1;
   gap: 10px;
   width: 100%;
   box-sizing: border-box;
   border: ${({ isInsideTable, variant }) =>
      isInsideTable
         ? "none"
         : `1px solid ${
              variant === "form" ? "#C8C8D0" : "var(--light-bg-opacity-20)"
           }`};

   border-radius: 8px;
   padding: ${(props) => (props.isInsideTable ? "0" : "0 16px")};
   background-color: ${(props) =>
      props.variant === "form" ? "transparent" : "var(--light-bg-opacity-05)"};
   color: ${(props) =>
      props.variant === "form" ? "#C8C8D0" : "var(--text-muted-inverted)"};
   height: ${(props) =>
      props.isTextArea ? "unset" : props.isInsideTable ? "26px" : "44px"};
   position: relative;

   input,
   textarea {
      width: 100%;
      flex: 1;
      border: none;
      outline: none;
      background-color: transparent;
      color: ${(props) =>
         props.variant === "form"
            ? "var(--text-primary)"
            : "var(--text-muted-inverted)"};
   }

   input::placeholder,
   textarea::placeholder {
      color: #898f9c;
   }

   input:focus,
   textarea:focus {
      outline: none;
   }

   textarea {
      padding: 14px 0;
      box-sizing: border-box;
      resize: none;
      width: 100%;
      height: auto;
      min-height: ${(props) => props.height || "200px"};
      font-size: 16px;
      line-height: 1.5;
      font-family: "Red Hat Display", sans-serif;
   }
`;

const InputComponent = ({
   type,
   value,
   handleChangeValue,
   placeholder,
   Icon,
   isTextArea,
   variant,
   height,
   isInsideTable,
   min,
   ...rest
}) => {
   return (
      <InputContainer
         isTextArea={isTextArea}
         variant={variant}
         height={height}
         isInsideTable={isInsideTable}
         {...rest}
      >
         {Icon && <Icon />}
         {isTextArea ? (
            <textarea
               value={value}
               placeholder={placeholder}
               onChange={handleChangeValue}
            />
         ) : (
            <input
               type={type}
               value={value}
               placeholder={placeholder}
               onChange={handleChangeValue}
               min={min}
            />
         )}
      </InputContainer>
   );
};

export default InputComponent;
